import React from "react"
import { Button } from "react-bootstrap"
import Layout from "@components/layout"
import SEO from "@components/seo"
import "@styles/404.scss"

const seo = {
  title: '404: Not found',
  url: 'https://blog.buildit.kr/404/',
  isHome: false,
  isPost: false,
  linkKey: '404',
  isMenu: false
}

const NotFoundPage = () => {
  const moveToPreviousPage = () => {
    let history = window.history
    history.back()
  }
  const moveToHome = () => {
    let loc = window.location
    window.location.href = loc.origin + '/'
  }
  return (
    <Layout>
      <SEO 
        title={seo.title}
        url={seo.url}
        isHome={seo.isHome}
        isPost={seo.isPost}
        linkKey={seo.linkKey}
        isMenu={seo.isMenu} />
      <div className="not-found-page">
        <h1 className="guide-text code">404</h1>
        <p className="guide-text result">Page Not Found</p>
        <p className="guide-text try">존재하지 않는 페이지입니다.</p>
        <div className="btn-block clearfix">
          <Button
            className="btn-move-home"
            variant="primary"
            onClick={moveToHome}>홈으로 이동</Button>
          <Button
            className="btn-move-prev"
            variant="outline-primary"
            onClick={moveToPreviousPage}>이전 페이지</Button>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
